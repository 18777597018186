import { Injectable } from '@angular/core';
import { Http, Response} from '@angular/http';
import { timeout, map } from 'rxjs/operators';
import { ApiConfig } from './config';

@Injectable()
export class BoardService {
  apiConfig:ApiConfig = new ApiConfig();

  headers:any;
  options:any;

  noticeParam={
    tableCode: ''
    , searchSeq: 0
    , commentSeq: 0
    , userId: ''
    , comments: ''
    , readLimit: 10    // 댓글 더보기시 가져오는 값
  };

  constructor(public http: Http) {

  }

  /**
   * 공지사항 가져오기
   */
  getBoardList(){
    const param_data = this.apiConfig.makeParameter(this.noticeParam);
    const url = this.apiConfig.apiObject.board+'/list';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(10000)).pipe(map((res: Response) => res.json()));

    return response;
  }

  /**
   * 공지사항 게시물 상세내용 가져오기
   */
  getBoardDetail(){
    const param_data = this.apiConfig.makeParameter(this.noticeParam);
    const url = this.apiConfig.apiObject.board+'/detail';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(10000)).pipe(map((res: Response) => res.json()));

    return response;
  }

  /**
   * 공지사항 게시물 코멘트 등록
   */
  registComment() {
    const param_data = this.apiConfig.makeParameter(this.noticeParam);
    const url = this.apiConfig.apiObject.board+'/registNoticeComment';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(10000)).pipe(map((res: Response) => res.json()));

    return response;    
  }

  /**
   * 댓글목록 가져오기
   */
  getNoticeComments() {
    const param_data = this.apiConfig.makeParameter(this.noticeParam);
    const url = this.apiConfig.apiObject.board+'/getNoticeComments';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(10000)).pipe(map((res: Response) => res.json()));

    return response;    
  }

  /**
   * 댓글삭제하기
   */
  delBoardComment() {
    const param_data = this.apiConfig.makeParameter(this.noticeParam);
    const url = this.apiConfig.apiObject.board+'/delBoardComment';
    const response = this.http.post(url, param_data, this.apiConfig.options).pipe(timeout(10000)).pipe(map((res: Response) => res.json()));

    return response;    
  }
}

/**
 * Created by bbang on 2017-04-12.
 */

export class JoinDetailModel{
  content:string;
  golf_id:number;
  golf_name:string;
  golf_name_view:string;
  green_fee:number;
  handphone:string;
  join_number:string;
  join_type:string;
  login_id:string;
  plaza_type:string;
  reg_am_pm:string;
  reg_time:string;
  regdate:string;
  upddate:string;
  seq:number;
  state:string;
  state_name:string;
  statusCode:string;
  subUserName:string;
  teeup_am_pm:string;
  teeup_date:string;
  teeup_day_name:string;
  teeup_time:string;
  user_id:string;
  user_name:string;
  coupleYn:string;
  userStatusCode:string;
  itsGender:string;
  itsPlazaOptions:string;
  updDateMMDD:string;
  updDateDay:string;
  updDateHHMi:string;

  constructor(){
    this.content = '';
    this.golf_id = 0;
    this.golf_name = '';
    this.golf_name_view = '';
    this.green_fee = 0;
    this.handphone = '';
    this.join_number = '4';
    this.join_type = '';
    this.login_id = '';
    this.plaza_type = '';
    this.reg_am_pm = '';
    this.reg_time = '';
    this.regdate = '';
    this.upddate = '';
    this.seq = 0;
    this.state = '';
    this.state_name = '';
    this.statusCode = '';
    this.subUserName = '';
    this.teeup_am_pm = '';
    this.teeup_date = '';
    this.teeup_day_name = '';
    this.teeup_time = '';
    this.user_id = '';
    this.user_name = '';
    this.coupleYn = '';
    this.userStatusCode = '';
    this.itsGender = '';
    this.itsPlazaOptions = '';
    this.updDateMMDD = '';
    this.updDateDay = '';
    this.updDateHHMi = '';
  }
}

import { Component, Input, ElementRef, Renderer, ViewEncapsulation  } from '@angular/core';
import { DomController } from '@ionic/angular';
import { Observable, fromEvent } from 'rxjs';
import { IfStmt } from '@angular/compiler';
 
@Component({
  selector: 'shrinking-segment-header',
  templateUrl: 'shrinking-segment-header.html', 
  styleUrls: ['shrinking-segment-header.scss']
  , encapsulation: ViewEncapsulation.None
})
export class ShrinkingSegmentHeader {
  @Input('scrollArea') scrollArea: any;
  @Input('headerHeight') headerHeight: number;

  newHeaderHeight: any;
  lastScrollTop = 0;
 
  constructor(public element: ElementRef, public renderer: Renderer, public domCtrl: DomController) {}
 
  ngAfterViewInit(){
    console.log("onit");

    this.renderer.setElementStyle(this.element.nativeElement, 'height', this.headerHeight + 'px');
    this.scrollArea.ionScroll.subscribe((ev) => {
      this.resizeHeader(ev);
    });
  }
 
  resizeHeader(ev){
    if(ev) {
      //console.log('위치 ==> ' + ev.detail.currentY);
      //console.log("이전위치 ===> " + this.lastScrollTop);
      let evy = ev.detail.currentY;
      if(evy < 0) evy = 0;

      if(evy > this.lastScrollTop) {
        /*
          리스트 개수가 몇개 안되어 스크롤링이 애매할때 버벅대는 현상을 해소.
          특정 길이 이상일때만 shrinking이 동작하도록 함.
        */
        if(evy > 200) this.setDomWrite('down');
      } else {
        if(evy < 80) {
          this.scrollArea.currentY = 0;
          this.setDomWrite('up');
        }        
      }
      this.lastScrollTop = evy;      
    }
  }

  setDomWrite(direction) {
    this.domCtrl.write(() => {
      if(direction === 'down') {
        for(let i=38; i>=0; i--) {
          this.renderer.setElementStyle(this.element.nativeElement, 'height', i + 'px');                 
        }
      } else if(direction === 'up') {
        for(let ii=0; ii<39; ii++) {
          this.renderer.setElementStyle(this.element.nativeElement, 'height', ii + 'px');                 
        }
      }
   });
  }
}